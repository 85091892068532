import { Pagination } from 'semantic-ui-react';

export const ReferenceFilterPagination = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  return (
    <div className="x-reference-filter-pagination">
      <Pagination
        onPageChange={(i, data) => onPageChange(data.activePage)}
        boundaryRange={0}
        defaultActivePage={1}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        siblingRange={2}
        totalPages={totalPages}
      />
    </div>
  );
};
