import { injectIntl } from 'react-intl';
import { CustomLogo } from '../../../../components/Logo';
import { BurgerButton } from '../../../../components/BurgerButton';
import { SmallHeaderDropdown } from '../../../../components/SmallHeaderDropdown';
import { NavbarFooter } from '../../../../components/NavbarFooter';

const SmallHeader = ({
  pathname,
  items,
  onBurgerClick,
  isExpanded,
  onClickedNavLink,
}) => {
  const homeRoutes = ['/de'];
  return (
    <>
      <CustomLogo></CustomLogo>
      <BurgerButton
        isExpanded={isExpanded}
        handleClick={onBurgerClick}
      ></BurgerButton>
      <div
        className={'responsive-navigation-wrapper'}
        aria-expanded={isExpanded}
      >
        {items.map((item, index) =>
          homeRoutes.includes(item.url) ? null : (
            <SmallHeaderDropdown
              onClickedNavLink={onClickedNavLink}
              key={index}
              item={item}
            />
          ),
        )}

        <NavbarFooter />
      </div>
    </>
  );
};

export default injectIntl(SmallHeader);
