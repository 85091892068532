import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getBaseUrl } from '@plone/volto/helpers/Url/Url';
import { hasApiExpander } from '@plone/volto/helpers/Utils/Utils';
import config from '@plone/volto/registry';
import { getNavigation } from '@plone/volto/actions/navigation/navigation';
import BigHeader from '../../../components/theme/Header/BigHeader';
import SmallHeader from './SmallHeader';

const Navigation = ({ pathname }) => {
  const navigation = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const items = useSelector((state) => state.navigation.items, shallowEqual);

  useEffect(() => {
    if (!hasApiExpander('navigation', getBaseUrl(pathname))) {
      dispatch(getNavigation(getBaseUrl(pathname), config.settings.navDepth));
    }
  }, [pathname, token, dispatch]);

  const leftNavTitles = ['Lösungen & Produkte', 'Referenzen', 'Forschung'];
  const rightNavTitles = ['Über uns', 'Karriere', 'Blog'];

  const [isExpanded, setIsExpanded] = useState(false);
  const handleBurgerClick = () => {
    setIsExpanded(!isExpanded);

    if (!isExpanded) {
      document.querySelectorAll('body').forEach((el) => {
        el.style.overflow = 'hidden';
      });
    } else {
      document.querySelectorAll('body').forEach((el) => {
        el.style.overflow = 'initial';
      });
    }
  };

  return (
    <>
      <div className={'x-nav-placeholder'}></div>
      <nav
        id="x-navigation"
        className={isExpanded ? 'x-expanded-nav' : ''}
        aria-label="navigation"
        ref={navigation}
      >
        <div className={'big-header-wrapper'}>
          <BigHeader
            pathname={pathname}
            leftNavTitles={leftNavTitles}
            rightNavTitles={rightNavTitles}
            items={items}
          ></BigHeader>
        </div>
        <div className={'small-header-wrapper'}>
          <SmallHeader
            items={items}
            onBurgerClick={handleBurgerClick}
            onClickedNavLink={handleBurgerClick}
            isExpanded={isExpanded}
          ></SmallHeader>
        </div>
      </nav>
    </>
  );
};

Navigation.propTypes = {
  pathname: PropTypes.string.isRequired,
};

Navigation.defaultProps = {
  token: null,
};

export default injectIntl(Navigation);
