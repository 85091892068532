export const HeadlineWithBubblesSchema = (props) => {
  return {
    title: 'HeadlineWithBubbles',
    fieldsets: [
      {
        id: 'customize',
        title: 'Customize',
        fields: ['darkTheme', 'customCSSClass'],
      },
      {
        id: 'default',
        title: 'Default',
        fields: ['header', 'bubbles'],
      },
    ],
    properties: {
      darkTheme: {
        title: 'Use Dark Theme',
        type: 'boolean',
      },
      customCSSClass: {
        title: 'Custom CSS Class',
        type: 'string',
      },
      header: {
        title: 'MainText',
        type: 'string',
        widget: 'text',
      },
      bubbles: {
        title: 'Bubbles',
        type: 'string',
        widget: 'richtext',
      },
    },
    required: ['header', 'bubbles'],
  };
};

export default HeadlineWithBubblesSchema;
