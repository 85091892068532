import icon from '@plone/volto/icons/list-bullet.svg';

import CustomEdit from './components/Custom/CustomEdit';
import CustomView from './components/Custom/CustomView';
import CustomBlockSchema from './components/Custom/schema';

import HeadlineWithBubblesEdit from './components/HeadlineWithBubbles/HeadlineWithBubblesEdit';
import HeadlineWithBubblesView from './components/HeadlineWithBubbles/HeadlineWithBubblesView';
import HeadlineWithBubblesSchema from './components/HeadlineWithBubbles/schema';

import ReferenceFilterEdit from './components/ReferenceFilter/ReferenceFilterEdit';
import ReferenceFilterView from './components/ReferenceFilter/ReferenceFilterView';
import ReferenceFilterSchema from './components/ReferenceFilter/schema';

const applyConfig = (config) => {
  config.settings.isMultilingual = true;
  config.settings.supportedLanguages = ['de', 'en'];
  config.settings.defaultLanguage = 'de';

  config.blocks.blocksConfig.customBlock = {
    id: 'customBlock',
    title: 'CUSTOM',
    edit: CustomEdit,
    view: CustomView,
    schema: CustomBlockSchema,
    icon: icon,
    group: 'text',
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.referenceFilter = {
    id: 'referenceFilter',
    title: 'ReferenceFilter',
    edit: ReferenceFilterEdit,
    view: ReferenceFilterView,
    schema: ReferenceFilterSchema,
    icon: icon,
    group: 'text',
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.headlineWithBubbles = {
    id: 'headlineWithBubbles',
    title: 'Headline With Bubbles',
    edit: HeadlineWithBubblesEdit,
    view: HeadlineWithBubblesView,
    schema: HeadlineWithBubblesSchema,
    icon: icon,
    group: 'text',
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.settings.DSGVOBanner = {
    ...(config.settings.DSGVOBanner || {}),
    tracker: {
      type: 'matomo',
      id: 40,
      urlBase: 'https://x-piwik01.x-net.at/',
    },
    modules: ['tracking'],
    privacy_url: '/de/datenschutz', // TODO: Add privacy url
  };

  return config;
};

export default applyConfig;
