import LanguageSelector from '../customizations/components/theme/LanguageSelector/LanguageSelector';

export const NavbarFooter = () => {
  return (
    <div className={'x-responsive-navbar-footer-wrapper'}>
      <div className={'x-responsive-navbar-socials'}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={'x-responsive-navbar-language-selector'}>
        <LanguageSelector></LanguageSelector>
      </div>
      <svg
        width="375"
        height="292"
        viewBox="0 0 375 292"
        fill="none"
        className={'x-responsive-navbar-footer-svg'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58 64.5C58 94.6435 34.6759 119 6 119C-22.6759 119 -46 94.6435 -46 64.5C-46 34.3565 -22.6759 10 6 10C34.6759 10 58 34.3565 58 64.5Z"
          strokeWidth="2"
        />
        <path
          d="M406 120.5C406 158.033 382.495 188 354 188C325.505 188 302 158.033 302 120.5C302 82.967 325.505 53 354 53C382.495 53 406 82.967 406 120.5Z"
          strokeWidth="2"
        />
        <path
          d="M111 111.5C111 141.643 87.6759 166 59 166C30.3241 166 7 141.643 7 111.5C7 81.3565 30.3241 57 59 57C87.6759 57 111 81.3565 111 111.5Z"
          strokeWidth="2"
        />
        <path
          d="M326 158.5C326 188.643 302.676 213 274 213C245.324 213 222 188.643 222 158.5C222 128.357 245.324 104 274 104C302.676 104 326 128.357 326 158.5Z"
          strokeWidth="2"
        />
        <path
          d="M188 200.5C188 250.53 149.45 291 102 291C54.5504 291 16 250.53 16 200.5C16 150.47 54.5504 110 102 110C149.45 110 188 150.47 188 200.5Z"
          strokeWidth="2"
        />
        <path
          d="M298 200.5C298 215.318 287.603 228.923 270.382 238.879C253.187 248.82 229.366 255 203 255C176.634 255 152.813 248.82 135.618 238.879C118.397 228.923 108 215.318 108 200.5C108 185.682 118.397 172.077 135.618 162.121C152.813 152.18 176.634 146 203 146C229.366 146 253.187 152.18 270.382 162.121C287.603 172.077 298 185.682 298 200.5Z"
          strokeWidth="2"
        />
        <path
          d="M415 259.5C415 309.53 376.45 350 329 350C281.55 350 243 309.53 243 259.5C243 209.47 281.55 169 329 169C376.45 169 415 209.47 415 259.5Z"
          strokeWidth="2"
        />
        <path
          d="M268.376 229.63C305.04 237.659 329.285 267.03 323.19 294.866C317.094 322.702 282.792 339.252 246.128 331.223C209.464 323.194 185.219 293.822 191.315 265.987C197.41 238.151 231.712 221.601 268.376 229.63Z"
          strokeWidth="2"
        />
        <path
          d="M75 279.5C75 309.643 51.6759 334 23 334C-5.67587 334 -29 309.643 -29 279.5C-29 249.357 -5.67587 225 23 225C51.6759 225 75 249.357 75 279.5Z"
          strokeWidth="2"
        />
        <path
          d="M427 55.5C427 85.6435 403.676 110 375 110C346.324 110 323 85.6435 323 55.5C323 25.3565 346.324 1 375 1C403.676 1 427 25.3565 427 55.5Z"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};
