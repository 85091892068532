import { useState } from 'react';
import { NavLink } from 'react-router-dom';

export const SmallHeaderDropdown = ({ item, onClickedNavLink }) => {
  const [isVisible, setIsVisible] = useState(false);

  const referenceUrls = ['/de/referenzen'];

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  function ListItem({ subItem, itemUrl }) {
    if (referenceUrls.includes(itemUrl)) {
      return (
        <li>
          <NavLink
            onClick={onClickedNavLink}
            to={subItem.url}
            className="x-responsive-nav-item-with-dropdown x-responsive-nav-references"
          >
            <div className={'x-responsive-nav-references-icon'}></div>
            <span>{subItem.title}</span>
          </NavLink>
        </li>
      );
    } else {
      return (
        <li>
          <NavLink
            onClick={onClickedNavLink}
            to={subItem.url}
            className="x-responsive-nav-item-with-dropdown"
          >
            <span>{subItem.title}</span>
          </NavLink>
        </li>
      );
    }
  }

  return (
    <div className="small-header-dropdown-wrapper">
      {item.items.length > 0 ? (
        <>
          <div className={'x-responsive-dropdown-wrapper'}>
            <button
              className={'x-responsive-dropdown-button'}
              onClick={handleClick}
            >
              <span
                className={`x-responsive-dropdown-title ${isVisible ? 'x-responsive-title-expanded' : ''}`}
              >
                {item.title}
              </span>

              <div
                className={`x-responsive-dropdown-arrow ${isVisible ? 'x-responsive-dropdown-expanded' : ''}`}
              >
                <svg
                  width="18"
                  height="11"
                  viewBox="0 0 18 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.00002 8.51058L1.601 1.11155L1.42438 0.934939L1.24761 1.11139L0.823386 1.53483L0.646284 1.71161L0.823223 1.88855L8.82322 9.88855L9 10.0653L9.17678 9.88855L17.1768 1.88855L17.3537 1.71161L17.1766 1.53483L16.7524 1.11139L16.5756 0.934939L16.399 1.11155L9.00002 8.51058Z"
                    strokeWidth="0.5"
                  />
                </svg>
              </div>
            </button>
          </div>
          {isVisible && (
            <ul className="small-header-dropdown-ul">
              {item.items.map((subItem, index) => (
                <ListItem
                  key={index}
                  subItem={subItem}
                  itemUrl={item.url}
                ></ListItem>
              ))}
            </ul>
          )}
        </>
      ) : (
        <NavLink
          onClick={onClickedNavLink}
          to={item.url}
          className="x-responsive-nav-item-no-dropdown"
        >
          <span className={'x-responsive-dropdown-title'}>{item.title}</span>
        </NavLink>
      )}
    </div>
  );
};
