import React from 'react';

const View = (props) => {
  const { data = {} } = props;

  return (
    <div className={data.darkTheme ? 'x-text-box dark' : 'x-text-box'}>
      {data.header && <h2>{data.header}</h2>}
      <div>
        {!data.textTwo ? (
          <div className="x-single-text">
            <p>{data.textOne}</p>
          </div>
        ) : (
          <div className="x-multiple-text">
            <p>{data.textOne}</p>
            <p>{data.textTwo}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default View;
