export const BurgerButton = ({ isExpanded, handleClick }) => {
  return (
    <button className={'burger-button'} onClick={handleClick}>
      <svg
        className={isExpanded ? ' open' : ''}
        width="34"
        height="22"
        viewBox="0 0 34 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={'line top'}
          d="M0.00012207 1L34.0001 1"
          strokeWidth="2.00347"
        />
        <path
          className={'line middle'}
          d="M0.00012207 11L34.0001 11"
          strokeWidth="2.00347"
        />
        <path
          className={'line bottom'}
          d="M0.00012207 21L34.0001 21"
          strokeWidth="2.00347"
        />
      </svg>
    </button>
  );
};
