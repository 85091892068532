import React, { useEffect, useState } from 'react';
import { ReferenceFilterPagination } from './ReferenceFilterPagination';
import { NavLink } from 'react-router-dom';

const View = ({ data }) => {
  const [sites, setSites] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = data.pageSize || 5;

  useEffect(() => {
    // This is used for the reference filter to pre-select a filter when in URL GET params.
    const urlParams = new URLSearchParams(window.location.search);
    const usedFilter = urlParams.get('filter');
    if (usedFilter) {
      setSelectedCategories([usedFilter]);
    }

    const fetchReferences = async () => {
      const response = await fetch(`/++api++${data.resourceUrl}`, {
        headers: {
          Accept: 'application/json',
        },
      });
      const referenceData = await response.json();

      for (const item of referenceData?.items) {
        if (item['@type'] === 'Folder') {
          fetchSites(item);
        }
      }
    };

    const fetchSites = async (referenceItem) => {
      try {
        const response = await fetch(
          `/++api++${new URL(referenceItem['@id']).pathname}`,
          {
            headers: {
              Accept: 'application/json',
            },
          },
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const jsonData = await response.json();

        jsonData.items.forEach((item, index) => {
          item.Subject.forEach((subject) => {
            setSites((prevSites) => {
              const newSites = { ...prevSites };
              if (Object.keys(newSites).includes(subject)) {
                newSites[subject] = [...newSites[subject], item]; // Create a new array to update the state immutably
              } else {
                newSites[subject] = [item];
              }

              return newSites; // Return the updated state
            });
          });
        });
      } catch (err) {
        console.error('Error fetching sites:', err);
      }
    };

    if (data.resourceUrl && data.categories) {
      fetchReferences();
    }
  }, [data.resourceUrl, data.categories]);

  const handleOnClick = (key) => {
    if (selectedCategories.includes(key)) {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== key),
      );
    } else {
      setSelectedCategories([...selectedCategories, key]);
    }
    setCurrentPage(1); // Reset to the first page when filters change
  };

  // Combine all items from the selected categories or all categories if none are selected
  const getAllSites = () => {
    const allSites = [];
    const categoriesToCombine =
      selectedCategories.length > 0 ? selectedCategories : Object.keys(sites);

    categoriesToCombine.forEach((category) => {
      if (sites[category]) {
        allSites.push(...sites[category]);
      }
    });

    // Remove duplicates, if a reference has 2 categories.
    return Array.from(
      new Map(allSites.map((item) => [item['@id'], item])).values(),
    );
  };

  const allSites = getAllSites().sort((a, b) => a.title.localeCompare(b.title));
  const totalPages = Math.ceil(allSites.length / pageSize);

  // Paginate the combined list of sites
  const paginatedSites = allSites.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const isActiveFilter = (key) => {
    return selectedCategories.includes(key);
  };

  return (
    <div className={'x-reference-filter-container'}>
      {!data.categories || !data.resourceUrl ? (
        <p>please fill out the component settings.</p>
      ) : (
        <>
          <div className={'x-filter-buttons-wrapper'}>
            {Object.keys(sites).map(
              (key) =>
                data.categories.split(',').includes(key) && (
                  <div key={key}>
                    <button
                      key={key}
                      className={`${isActiveFilter(key) ? 'x-active-filter' : ''}`}
                      onClick={() => handleOnClick(key)}
                    >
                      {key}
                    </button>
                  </div>
                ),
            )}
          </div>
          <div className={'x-references-container'}>
            {paginatedSites.map((site, index) => (
              <div key={index} className={'x-references-item'}>
                <NavLink
                  key={`${index}-x-reference-link-no-border`}
                  to={new URL(site['@id']).pathname}
                  className="x-reference-link-no-border"
                >
                  <h3>{site.title}</h3>
                </NavLink>

                <NavLink
                  key={`${index}-x-reference-link`}
                  to={new URL(site['@id']).pathname}
                  className="x-reference-link"
                >
                  Mehr dazu
                </NavLink>
              </div>
            ))}
          </div>

          {/* Pagination controls */}
          {totalPages > 1 && (
            <ReferenceFilterPagination
              onPageChange={handlePageChange}
              totalPages={totalPages}
            />
          )}
        </>
      )}
    </div>
  );
};

export default View;
