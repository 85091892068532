export const ReferenceFilterSchema = (props) => {
  return {
    title: 'ReferenceFilter',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['resourceUrl', 'categories', 'pageSize'],
      },
      {
        id: 'customize',
        title: 'Customize',
        fields: ['darkTheme'],
      },
    ],
    properties: {
      resourceUrl: {
        title: 'resourceUrl',
        type: 'string',
        widget: 'text',
      },
      categories: {
        title: 'categories',
        type: 'string',
        widget: 'text',
      },
      pageSize: {
        title: 'pageSize',
        type: 'number',
        widget: 'text',
      },
      darkTheme: {
        title: 'Use Dark Theme',
        type: 'boolean',
      },
    },
    required: [],
  };
};

export default ReferenceFilterSchema;
