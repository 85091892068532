import { CustomLogo } from '../../../../components/Logo';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import React, { useState } from 'react';

const BigHeader = ({ pathname, items, leftNavTitles, rightNavTitles }) => {
  const [navMaxExpandedItems, setNavMaxExpandedItems] = useState(null);

  const isActive = (url) => {
    return (url === '' && pathname === '/') || (url !== '' && pathname === url);
  };

  const getLeftNavItems = (itemsToChange) => {
    return itemsToChange.filter((item) => {
      return leftNavTitles.includes(item.title);
    });
  };

  const getRightNavItems = (itemsToChange) => {
    return itemsToChange.filter((item) => {
      return rightNavTitles.includes(item.title);
    });
  };

  const checkIfSubGroupIsHovered = (listObject) => {
    return listObject === navMaxExpandedItems;
  };

  const removeBlockHoverEffectFromNavItem = () => {
    Array.from(
      document.getElementsByClassName('x-nav-item-expanded-box'),
    ).forEach((element) => {
      element.classList.remove('x-block-expand');
    });
  };

  const addBlockHoverEffectToNavItem = () => {
    Array.from(
      document.getElementsByClassName('x-nav-item-expanded-box'),
    ).forEach((element) => {
      element.classList.add('x-block-expand');
    });
  };

  return (
    <>
      <div className="x-nav-wrapper">
        <ul className="x-nav-items-wrapper-left">
          {getLeftNavItems(items).map((item, index) => (
            <li key={index} className="x-nav-item x-nav-item-with-childs">
              <NavLink
                onClick={addBlockHoverEffectToNavItem}
                onMouseEnter={() => {
                  removeBlockHoverEffectFromNavItem();
                }}
                to={item.url}
                className="x-nav-item-link"
              >
                <span>{item.title}</span>
              </NavLink>

              <div className="x-nav-item-expanded-box">
                {item.url !== '/de/referenzen' ? (
                  <>
                    <div className="x-nav-item-list-border-wrapper x-nav-item-childs">
                      <div className="x-nav-item-border">
                        <ul className="x-nav-list-item-childs x-nav-item-childs-left">
                          {item.items.map((xItem, index) => (
                            <li
                              onMouseEnter={() => {
                                setNavMaxExpandedItems(xItem);
                              }}
                              onMouseLeave={() => {
                                setNavMaxExpandedItems(null);
                              }}
                              className={
                                checkIfSubGroupIsHovered(xItem)
                                  ? 'li-hovered'
                                  : ''
                              }
                              key={xItem.url}
                            >
                              <NavLink
                                to={xItem.url}
                                className={cx({
                                  current: isActive(xItem.url),
                                })}
                                onClick={addBlockHoverEffectToNavItem}
                                onMouseEnter={() => {
                                  removeBlockHoverEffectFromNavItem();
                                }}
                              >
                                <p>{xItem.title}</p>
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div
                      className="x-nav-item-list-border-wrapper x-nav-item-childs"
                      onMouseEnter={() => {
                        setNavMaxExpandedItems(navMaxExpandedItems);
                      }}
                      onMouseLeave={() => {
                        setNavMaxExpandedItems(null);
                      }}
                    >
                      <div
                        className={
                          navMaxExpandedItems !== null
                            ? 'x-nav-item-border'
                            : ''
                        }
                      >
                        <ul className="x-nav-list-item-childs x-nav-list-item-childs-right">
                          {navMaxExpandedItems !== null ? (
                            navMaxExpandedItems.items.map((yItem, index) => (
                              <li key={yItem.url}>
                                <NavLink
                                  to={yItem.url}
                                  className={cx({
                                    current: isActive(yItem.url),
                                  })}
                                  onClick={addBlockHoverEffectToNavItem}
                                  onMouseEnter={() => {
                                    removeBlockHoverEffectFromNavItem();
                                  }}
                                >
                                  <p className="x-nav-right-text">
                                    {yItem.title}
                                  </p>
                                </NavLink>
                              </li>
                            ))
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="x-nav-item-childs">
                      <div className="x-nav-image-container"></div>
                    </div>
                  </>
                ) : (
                  <div className="x-nav-expanded-box-reference">
                    {item.items
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((item, index) => (
                        <NavLink
                          key={index}
                          to={item.url}
                          className="x-nav-item-reference"
                          onClick={addBlockHoverEffectToNavItem}
                          onMouseEnter={() => {
                            removeBlockHoverEffectFromNavItem();
                          }}
                        >
                          <div className="x-nav-item-reference-img-placeholder"></div>
                          <p>{item.title}</p>
                        </NavLink>
                      ))}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
        {/*<NavLink to={'/'} className={'x-nav-logo'}>*/}
        <CustomLogo></CustomLogo>
        {/*</NavLink>*/}
        <ul className="x-nav-items-wrapper-right">
          {getRightNavItems(items).map((item, index) => (
            <li key={index} className="x-nav-item x-nav-item-with-childs">
              <NavLink
                to={item.url}
                className={cx({
                  current: isActive(item.url),
                })}
              >
                <span>{item.title}</span>
              </NavLink>
            </li>
          ))}
          <li className="x-nav-item-social-media-link"></li>
          <li className="x-nav-item-social-media-link"></li>
          <li className="x-nav-item-social-media-link"></li>
          <li className="x-nav-item-language-selector">
            <LanguageSelector />
          </li>
        </ul>
      </div>
    </>
  );
};

export default injectIntl(BigHeader);
