export const CustomLogo = () => {
  return (
    <svg
      width="109"
      height="28"
      viewBox="0 0 109 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="customLogo"
    >
      <g>
        <path d="M43.4743 3.6078C43.7415 5.51791 43.7597 7.43007 43.7556 9.34631C43.7435 15.3095 43.7516 21.2728 43.7516 27.236C43.7516 27.7426 43.502 27.9959 43.0028 27.9959C42.1671 27.9959 41.3333 27.9959 40.4976 27.9959C39.8379 27.9959 39.8217 27.9755 39.8176 27.2891C39.8176 27.2278 39.8176 27.1665 39.8176 27.1052C39.8176 18.3514 39.8176 9.59758 39.8176 0.845797C39.8176 0.661936 39.8176 0.478075 39.8318 0.294213C39.8439 0.114438 39.9451 0.0163789 40.1293 0.0163789C40.2203 0.0163789 40.3114 0.0102502 40.4025 0.0102502C41.5256 0.0102502 42.6507 0.0184218 43.7738 0.00820734C44.7614 -0.00200716 45.4757 0.416788 45.9756 1.28502C48.8491 6.26357 51.7348 11.238 54.6226 16.2084C55.5433 17.7957 56.4479 19.3912 57.403 20.9582C57.9535 21.8611 58.3663 22.8356 58.7609 23.8162C58.8216 23.9653 58.8904 24.1124 59.026 24.2391C58.9896 23.9244 58.9612 23.6078 58.9188 23.2932C58.7326 21.9244 58.686 20.5455 58.686 19.1665C58.682 13.0644 58.686 6.96429 58.684 0.86214C58.684 -0.151139 58.767 0.0184218 59.536 0.0122931C60.3717 0.00412154 61.2055 0.00820734 62.0412 0.0122931C62.6038 0.0122931 62.6159 0.0286364 62.62 0.614949C62.62 0.706879 62.62 0.79881 62.62 0.890741C62.62 9.64457 62.62 18.3984 62.62 27.1502C62.62 27.2891 62.624 27.426 62.62 27.5649C62.6058 27.9326 62.5613 27.9898 62.1849 27.9919C61.0011 28 59.8152 28.0082 58.6314 27.9898C57.6783 27.9735 56.9801 27.5526 56.4863 26.6905C53.5642 21.5976 50.6218 16.5169 47.6774 11.4362C46.8639 10.0348 46.0767 8.61699 45.2167 7.24416C44.6137 6.284 44.1462 5.25029 43.7172 4.20024C43.6362 4.00412 43.5593 3.80596 43.4804 3.6078H43.4743Z" />
        <path d="M67.584 13.1787C67.584 9.05614 67.584 4.93357 67.584 0.810995C67.584 0.337042 67.8174 0.0993844 68.2842 0.0980225C73.4768 0.0980225 78.6675 0.0980225 83.8601 0.0980225C84.3296 0.0980225 84.565 0.334318 84.5664 0.806909C84.5664 1.25022 84.5846 1.69353 84.4389 2.12662C84.1353 3.02959 83.5019 3.5444 82.5609 3.61794C82.395 3.6302 82.227 3.62611 82.0611 3.62611C78.8435 3.62611 75.6239 3.62611 72.4063 3.62611C71.9598 3.62611 71.7365 3.8556 71.7365 4.31457C71.7365 6.61283 71.7365 8.91314 71.7365 11.2114C71.7365 11.334 71.7405 11.4566 71.7405 11.5791C71.7405 11.7916 71.8498 11.8835 72.0522 11.8794C72.1878 11.8794 72.3254 11.8815 72.463 11.8815C76.1076 11.8815 79.7501 11.8815 83.3947 11.8815C84.1191 11.8815 84.1171 11.8815 84.1232 12.6149C84.1272 13.0909 84.1232 13.5628 83.9492 14.0184C83.676 14.7334 83.1822 15.1767 82.4375 15.3381C82.1076 15.4096 81.7737 15.4116 81.4398 15.4116C78.4185 15.4116 75.3973 15.4116 72.376 15.4116C71.9523 15.4116 71.7399 15.62 71.7385 16.0367C71.7385 18.5801 71.7385 21.1256 71.7385 23.669C71.7385 23.716 71.7385 23.7609 71.7385 23.8079C71.7466 24.2941 71.7668 24.3207 72.2647 24.3207C75.118 24.3227 77.9733 24.3207 80.8267 24.3207C81.9498 24.3207 83.0729 24.3207 84.196 24.3207C84.3175 24.3207 84.4389 24.3227 84.5603 24.3268C84.7748 24.333 84.9003 24.4371 84.9084 24.6639C84.9104 24.7252 84.9165 24.7865 84.9165 24.8478C84.9165 25.6915 84.9225 26.5332 84.9165 27.3769C84.9124 27.8284 84.8557 27.8794 84.4207 27.8958C84.3296 27.8999 84.2385 27.8958 84.1475 27.8958C79.5174 27.8958 74.8873 27.8958 70.2572 27.8958C69.9375 27.8958 69.6198 27.8754 69.3081 27.8039C68.2275 27.5607 67.6042 26.7947 67.5941 25.6792C67.584 24.6517 67.5921 23.6261 67.5921 22.5985C67.5921 19.4566 67.5921 16.3146 67.5921 13.1726L67.584 13.1787Z" />
        <path d="M99.7922 15.6711C99.7922 19.5015 99.7922 23.334 99.7922 27.1645C99.7922 27.8979 99.7922 27.9019 99.0556 27.8979C98.5396 27.8958 98.0235 27.9305 97.5116 27.8407C96.2953 27.6262 95.6478 26.8662 95.6478 25.6159C95.6458 18.5209 95.6478 11.4259 95.6458 4.33095C95.6458 3.49541 95.7267 3.54035 94.8525 3.54035C92.6204 3.54035 90.3904 3.54035 88.1583 3.54035C87.4925 3.54035 87.4743 3.52196 87.4723 2.83964C87.4723 2.0572 87.4662 1.27682 87.4743 0.494385C87.4783 0.0612902 87.5249 0.022475 87.9458 0.0122605C88.0369 0.0102176 88.1279 0.0122605 88.219 0.0122605C94.7311 0.0122605 101.243 0.0122605 107.755 0.0122605C108.524 0.0122605 108.526 0.0122605 108.522 0.796735C108.522 1.14811 108.544 1.49949 108.463 1.84883C108.215 2.92135 107.468 3.53831 106.383 3.54035C104.424 3.54444 102.467 3.54035 100.509 3.54035C100.387 3.54035 100.266 3.53626 100.144 3.54444C99.8489 3.56487 99.8104 3.60368 99.7942 3.90194C99.7861 4.03882 99.7922 4.17774 99.7922 4.31461C99.7922 8.10011 99.7922 11.8856 99.7922 15.6711Z" />
        <path d="M16.5337 10.1164L9.06243 0.165475C8.98553 0.061287 8.86411 0 8.7346 0H3.29303C3.16352 0 3.0421 0.061287 2.96318 0.165475L0.350662 3.68335C0.239362 3.83248 0.241386 4.03677 0.350662 4.18386L7.75111 13.9837L0.364828 23.8182C0.253528 23.9653 0.253528 24.1696 0.364828 24.3187L2.9733 27.8243C3.0502 27.9285 3.17364 27.9898 3.30315 27.9898H8.74067C8.87018 27.9898 8.9916 27.9285 9.0685 27.8264L16.5357 17.8999L24.003 27.8264C24.0799 27.9305 24.2013 27.9898 24.3308 27.9898H29.7683C29.8978 27.9898 30.0192 27.9285 30.0982 27.8243L32.7066 24.3187C32.8179 24.1696 32.8159 23.9653 32.7066 23.8182L25.3183 13.9857L32.7188 4.1859C32.8301 4.03882 32.8321 3.83248 32.7188 3.68539L30.1042 0.165475C30.0273 0.061287 29.9039 0 29.7744 0H24.3328C24.2033 0 24.0819 0.061287 24.005 0.165475L16.5337 10.1164Z" />
      </g>
      <defs>
        <clipPath id="clip0_89_7758">
          <rect
            width="108.259"
            height="28"
            fill="white"
            transform="translate(0.267578)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
