import PropTypes from 'prop-types';

const BreadcrumbsComponent = ({ pathname }) => {
  return <></>;
};

BreadcrumbsComponent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default BreadcrumbsComponent;
