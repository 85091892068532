export const CustomBlockSchema = (props) => {
  return {
    title: 'Custom Block',
    fieldsets: [
      {
        id: 'customize',
        title: 'Customize',
        fields: ['darkTheme'],
      },
      {
        id: 'default',
        title: 'Default',
        fields: ['header', 'textOne', 'textTwo'],
      },
    ],
    properties: {
      darkTheme: {
        title: 'Use Dark Theme',
        type: 'boolean',
      },
      header: {
        title: 'Header',
        type: 'string',
        widget: 'text',
      },
      textOne: {
        title: 'Text One',
        type: 'string',
        widget: 'text',
      },
      textTwo: {
        title: 'Text Two',
        type: 'string',
        widget: 'text',
      },
    },
    required: ['header', 'textOne'],
  };
};

export default CustomBlockSchema;
