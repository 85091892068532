import React from 'react';

const View = (props) => {
  const { data = {} } = props;

  const parseBubbleData = (bubble) => {
    if (!bubble) return []; // Handle undefined or null input
    return bubble.replace(/<p>/g, '').replace(/<\/p>/g, '').split(','); // Use regex to replace all occurrences
  };

  const bubbles = data.bubbles ? parseBubbleData(data.bubbles.data) : [];

  return (
    <div
      className={
        data.darkTheme
          ? `x-text-box dark ${data.customCSSClass ? data.customCSSClass : ''}`
          : `x-text-box ${data.customCSSClass ? data.customCSSClass : ''}`
      }
    >
      {data.header && <h2>{data.header}</h2>}
      {bubbles.map((bubble, index) => (
        <div
          className={'asd'}
          key={index}
          dangerouslySetInnerHTML={{ __html: bubble }} // Ensure the content is sanitized if it comes from an untrusted source
        ></div>
      ))}
    </div>
  );
};

export default View;
